module.exports.DOCUMENT_TYPE_CONTRACT_MND = "contract_signed";
module.exports.DOCUMENT_TYPE_POA_MND = "power_of_attorney";
module.exports.DOCUMENT_TYPE_POA = "power_of_attorney_previous_owner";
module.exports.DOCUMENT_TYPE_POA_OFFLINE = "power_of_attorney_previous_owner_offline";
module.exports.DOCUMENT_TYPE_APPLICATION_FOR_GRID_CONNECTION_OFFLINE = "application_for_grid_connection_offline";
module.exports.DOCUMENT_TYPE_APPLICATION_FOR_GRID_CONNECTION = "application_for_grid_connection";
module.exports.DOCUMENT_TYPE_PURCHASE_CONTRACT = "property_contract_purchase";
module.exports.DOCUMENT_TYPE_LEASE_CONTRACT = "property_contract_lease";
module.exports.DOCUMENT_TYPE_LAND_REGISTRY_DOCUMENT = "land_registry_document";
module.exports.DOCUMENT_TYPE_TRANSFER_PROTOCOL = "handover_protocol";
module.exports.DOCUMENT_TYPE_CERTIFICATE_OF_RELATION_TO_PROPERTY = "relation_to_property_document";
module.exports.DOCUMENT_TYPE_REVISION = "revision";
module.exports.DOCUMENT_TYPE_OTHER_DOCUMENTS = "other_documents";
module.exports.DOCUMENT_TYPE_OTHER_REQUESTED_DOCUMENT = "other_requested_document";
module.exports.DOCUMENT_TYPE_OTHER_REASON = 'other_reason';
module.exports.DOCUMENT_TYPE_REPRESENTATION_OF_THE_OWNER = "representation_of_the_owner";
module.exports.DOCUMENT_TYPE_TERMINATION_OF_CONTRACT_PREVIOUS_OWNER = "contract_termination_previous_owner";
module.exports.DOCUMENT_TYPE_POA_CEZ = "power_of_attorney_cez";
module.exports.DOCUMENT_TYPE_DEATH_CERTIFICATE = "death_certificate";
module.exports.DOCUMENT_TYPE_SURPLUS_PURCHASE_CONTRACT = "fve_surplus_purchase_contract";
module.exports.DOCUMENT_TYPE_BAIL = "bail";
module.exports.DOCUMENT_TYPE_CHANGE_DISTRIBUTION_RATE_OR_METER = "change_distribution_rate_or_meter";
module.exports.DOCUMENT_TYPE_ORIGINAL_SUPPLIER_INVOICE =  "original_supplier_invoice";
module.exports.DOCUMENT_TYPE_AFFIDAVIT_OF_D_RATE =  "affidavit_of_d_rate";

module.exports.DOCUMENT_TYPE_DECLARATION_OF_HONOR = "declaration_of_honor";
module.exports.DOCUMENT_TYPE_COMPLAINT_FORM = "complaint_form";
module.exports.DOCUMENT_TYPE_SUBJECT_PERSONAL_DATA_CORRECTION = "subject_personal_data_correction";
module.exports.DOCUMENT_TYPE_OM_TRANSCRIPT_CONSENT = "om_transcript_consent";
module.exports.DOCUMENT_TYPE_CONTRACT_WITHDRAWAL = "contract_withdrawal";
module.exports.DOCUMENT_TYPE_CONTRACT_TERMINATION = "contract_termination";
module.exports.DOCUMENT_TYPE_POWER_OF_ATTORNEY_ON_BEHALF = "power_of_attorney_on_behalf";
module.exports.DOCUMENT_TYPE_ID_CARD_COPY = "id_card_copy";
module.exports.DOCUMENT_TYPE_MARRIAGE_CERTIFICATE = "marriage_certificate";
module.exports.DOCUMENT_TYPE_REVISION_AND_PRESSURE_TEST = "revision_and_pressure_test";
module.exports.DOCUMENT_TYPE_TERMINATION_AGREEMENT = "termination_agreement";


module.exports.DOCUMENT_TYPE_TERMINATION_WITHDRAWAL = "termination_withdrawal";
module.exports.DOCUMENT_TYPE_CONSENT_TO_SANCTION = "consent_to_sanction";
module.exports.DOCUMENT_TYPE_CONSENT_TO_SANCTION_MND = "consent_to_sanction_mnd";


exports.DOCUMENT_TYPE_PPP_SURPLUS_PURCHASE_WITHDRAWAL = "ppp_surplus_purchase_withdrawal";
exports.DOCUMENT_TYPE_PPP_SURPLUS_PURCHASE_TERMINATION_WITHDRAWAL = "ppp_surplus_purchase_termination_withdrawal";
exports.DOCUMENT_TYPE_AFFIDAVIT_OF_DEATH = "affidavit_of_death";
exports.DOCUMENT_TYPE_EGD = "egd_d55";














module.exports.SITUATION_SUPPLIER_CHANGE = "SUPPLIER_CHANGE";

module.exports.PERSON_TYPE_PERSON = "PERSON";
module.exports.PERSON_TYPE_BUSINESS = "BUSINESS";

module.exports.VIEWER_TYPE_OWNER = "OWNER";
module.exports.VIEWER_TYPE_THIRD_PARTY = "THIRD_PARTY";
module.exports.VIEWER_TYPE_PARTNER = "PARTNER";

module.exports.VIEWER_PROCESS_DEFAULT = "DEFAULT";
module.exports.VIEWER_PROCESS_DOCUMENTS = "DOCUMENTS";
module.exports.VIEWER_PROCESS_SIGNATURE = "SIGNATURE";
module.exports.VIEWER_PROCESS_SIGNATURE_PM = "SIGNATURE_PM";

module.exports.VALID_SIGN_SIZE_PERCENTAGE = 0.5;
module.exports.REDIRECT_TIMEOUT = 3; //sec

module.exports.DOCUMENT_REQUEST_STATE_NONE = "none";
module.exports.DOCUMENT_REQUEST_STATE_COMPLETED = "completed";
module.exports.DOCUMENT_REQUEST_STATE_SIGNATURE_CONFIRMED = "signature_confirmed";

module.exports.DEFAULT_PREVIEW = "/images/file-preview.svg";
module.exports.DEFAULT_PREVIEW_MND = "/images/plna_moc_podpis.png";
module.exports.DEFAULT_PREVIEW_POA_CEZ = "/images/cez_poa.jpg";
module.exports.DEFAULT_PREVIEW_POA_MND = "/images/plna_moc_podpis.png";
module.exports.DEFAULT_PREVIEW_CONTRACT = "/images/smlouva_podpis.jpg";
module.exports.LAND_REGISTRY_PREVIEW = "/images/vypis_z_katastru_vzor.png";

module.exports.MAX_SIZE = 20000000;
module.exports.ALLOWED_FILE_TYPES = "image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
module.exports.PREVIEW_FILE_TYPES = ["image/png", "image/gif", "image/jpeg"];
module.exports.PDF_FILE_TYPE = "application/pdf";

module.exports.PRODUCTION_HOST_NAME = "podpis.mnd.cz";

// TODO: Brát url k online formuláři dle prostředí
// module.exports.VOP_FILE_URL = `${process.env.ONLINE_FORMULAR_URL}/data/pdf/vop.pdf`;
module.exports.VOP_FILE_URL = "https://formular.mnd.cz/data/pdf/vop.pdf";